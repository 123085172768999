import { renderDatatable } from "../application"

columns = [
  { data: "name" },
  { data: "group_members_count" },
  { data: "creator_first_name" },
  { data: "creator_last_name" },
  { data: "creator_email" },
  { data: "created_at" }
]

order = [[5, "desc"]]

renderDatatable($('#admin-groups-datatable'), columns, order)
