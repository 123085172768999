import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select"

export default class extends Controller {
  connect() {
    this.tomSelect = new TomSelect(this.element, {
      plugins: ['remove_button'],
      create: true,
      preload: true,
      valueField: 'id',
      labelField: 'name',
      searchField: 'name',
      load: (query, callback) => {
        const url = `${this.element.dataset.sourceUrl}?query=${encodeURIComponent(query)}`
        fetch(url)
          .then(response => response.json())
          .then(json => {
            callback(json)
          }).catch(() => {
            callback()
          })
      },
      render: {
        no_results:function(data, escape){
          return '';
        },
        option_create: function(data, escape) {
          return '<div class="create">Add <strong>' + escape(data.input) + '</strong>&hellip;</div>';
        }
      }
    })
  }

  disconnect() {
    this.tomSelect.destroy()
  }
}
