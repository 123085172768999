import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js";

export default class extends Controller {
  static targets = [ "cta", "professionOption", "specialityOption", "specialitySection", "selectedSpecialityOption", "gradeQuestionSection", "gradeQuestion" ]
  static values = {
    placeholder: String,
    specialitiesUrl: String
  }

  connect() {
    this.toggleUi(false)
  }

  toggle() {
    this.toggleUi(true)
  }

  toggleUi(fetchNewData) {
    const selectedOption = this.fetchSelectedOption(this.professionOptionTargets)

    if (!selectedOption) {
      this.hideSpecialitySection()
      this.hideGradeQuestionSection()
      this.hideCta()
      return
    }

    if (selectedOption.dataset.reveal == "true") {
      this.hideSpecialitySection()
    } else {
      if (fetchNewData) {
        this.updateSpecialityValues(selectedOption);
      }

      this.showSpecialitySection()
    }

    this.toggleGradeSection()
    this.showCta()
  }

  updateSpecialityValues(selectedOption) {
    const selectedProfession = selectedOption.dataset.id

    get(this.specialitiesUrlValue, {
      query: { profession_id: selectedProfession },
      responseKind: 'turbo-stream'
    })

    this.clearSpecialityValues()
  }

  clearSpecialityValues() {
    // clear any existing values
    this.selectedSpecialityOptionTarget.placeholder = this.placeholderValue
    this.selectedSpecialityOptionTarget.value = ''
  }

  showSpecialitySection() {
    this.specialitySectionTarget.classList.remove(this.hiddenCssClass())
  }

  hideSpecialitySection() {
    this.specialitySectionTarget.classList.add(this.hiddenCssClass())

    this.clearSpecialityValues()
  }

  toggleGradeSection() {
    const selectedOption = this.fetchSelectedOption(this.specialityOptionTargets)

    if (!selectedOption) {
      return;
    }

    if (selectedOption.dataset.gradeRequired == 'true') {
      this.showGradeQuestionSection()
    } else {
      this.hideGradeQuestionSection()
    }
  }

  showGradeQuestionSection() {
    this.gradeQuestionSectionTarget.classList.remove(this.hiddenCssClass())
  }

  hideGradeQuestionSection() {
    this.gradeQuestionSectionTarget.classList.add(this.hiddenCssClass())
    this.gradeQuestionTarget.value = '';
  }

  showCta() {
    if (this.hasCtaTarget) {
      this.ctaTarget.classList.remove(this.hiddenCssClass())
    }
  }

  hideCta() {
    if (this.hasCtaTarget) {
      this.ctaTarget.classList.add(this.hiddenCssClass())
    }
  }

  hiddenCssClass() {
    return 'hidden'
  }

  fetchSelectedOption(target) {
    return target.find((target) => target.classList.contains('text-white', 'bg-blue-600'))
  }
}
