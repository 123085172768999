import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "button", "label", "hiddenField" ]

  toggle() {
    if (this.hiddenFieldTarget.value == "false") {
      this.buttonTarget.classList.remove("bg-gray-200")
      this.buttonTarget.classList.add("bg-blue-600")

      this.labelTarget.classList.remove("translate-x-0")
      this.labelTarget.classList.add("translate-x-5")

      this.hiddenFieldTarget.value = "true"
    } else {
      this.buttonTarget.classList.remove("bg-blue-600")
      this.buttonTarget.classList.add("bg-gray-200")

      this.labelTarget.classList.remove("translate-x-5")
      this.labelTarget.classList.add("translate-x-0")

      this.hiddenFieldTarget.value = "false"
    }
  }
}
