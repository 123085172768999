import { renderDatatable } from "../application"

columns = [
  { data: "name" },
  { data: "organiser_first_name" },
  { data: "organiser_last_name" },
  { data: "organiser_email" },
  { data: "provider" },
  { data: "certified_by" },
  { data: "accrediation_points" },
  {
    data: 'published',
    render: function(data, _) {
      if (data === 'Live') {
        return `<span class="inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset bg-emerald-50 text-emerald-700 ring-emerald-700/10">${data}</span>`
      } else if (data === 'Archived') {
        return `<span class="inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset bg-red-50 text-red-700 ring-red-600/10">${data}</span>`
      } else {
        return `<span class="inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset bg-yellow-50 text-yellow-800 ring-yellow-600/20">${data}</span>`
      }
    }
  },
  { data: "private" },
  { data: "promoted" },
  { data: "created_at" },
  { data: "discarded_at" },
  { data: "actions", searchable: false, orderable: false }
]

order = [[10, "desc"]]

renderDatatable($('#admin-courses-datatable'), columns, order)
