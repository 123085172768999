import jquery from 'jquery'
import DataTable from "datatables.net-dt"
import "datatables.net-select"

window.jQuery = jquery
window.$ = jquery

export { jquery }
export { DataTable }

export function renderDatatable(element, columns, order = [], select_rows = false) {
  if (element.length != 0) {
    configuration = {
      processing: true,
      serverSide: true,
      pagingType: "simple_numbers",
      lengthMenu: [25, 50, 75, 100],
      order: order,
      columns: columns,
      ajax: {
        url: element.data('source')
      }
    }

    if (select_rows) {
      configuration.select = {
        style: 'os',
        selector: 'td:first-child',
        info: false
      }
    }

    element.DataTable(configuration);
  }
}
