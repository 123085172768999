import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js";

export default class extends Controller {
  static targets = [ "cta", "timezoneOption", "timezoneSection", "countryOption", "timezoneQuestion", "selectedTimezoneOption" ]
  static values = {
    timezonesUrl: String,
    placeholder: String
  }

  connect() {
    this.toggleUi(false)
  }

  toggle() {
    this.toggleUi(true)
  }

  toggleUi(fetchNewData) {
    const selectedOption = this.fetchSelectedOption(this.countryOptionTargets)

    if (!selectedOption) {
      this.hidetimezoneSection()
      this.hideCta()
      return
    }

    if (fetchNewData) {
      this.updateTimezoneValues(selectedOption);
    }

    this.showtimezoneSection()
    this.showCta()
  }

  updateTimezoneValues(selectedOption) {
    const selectedCountry = selectedOption.dataset.id

    get(this.timezonesUrlValue, {
      query: { country: selectedCountry },
      responseKind: 'turbo-stream'
    })

    this.clearTimezoneValues()
  }

  clearTimezoneValues() {
    // clear any existing values
    this.selectedTimezoneOptionTarget.placeholder = this.placeholderValue
    this.selectedTimezoneOptionTarget.value = ''
  }

  showtimezoneSection() {
    this.timezoneSectionTarget.classList.remove(this.hiddenCssClass())
  }

  hidetimezoneSection() {
    this.timezoneSectionTarget.classList.add(this.hiddenCssClass())
  }

  showCta() {
    if (this.hasCtaTarget) {
      this.ctaTarget.classList.remove(this.hiddenCssClass())
    }
  }

  hideCta() {
    if (this.hasCtaTarget) {
      this.ctaTarget.classList.add(this.hiddenCssClass())
    }
  }

  hiddenCssClass() {
    return 'hidden'
  }

  fetchSelectedOption(target) {
    return target.find((target) => target.classList.contains('text-white', 'bg-blue-600'))
  }
}
