import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { id: String }

  connect() {
    this.startPolling()
  }

  disconnect() {
    this.stopPolling()
  }

  startPolling() {
    this.pollingInterval = setInterval(() => {
      this.checkReportStatus()
    }, 7000)
  }

  stopPolling() {
    if (this.pollingInterval) {
      clearInterval(this.pollingInterval)
    }
  }

  async checkReportStatus() {
    try {
      const response = await fetch(`/reports/${this.idValue}/status`)
      const data = await response.json()

      if (data.status === 'completed') {
        this.stopPolling()
        this.replaceWithCompletedReport(data.html)
      }
    } catch (error) {
      console.error('Error checking report status:', error)
    }
  }

  replaceWithCompletedReport(html) {
    const generatingContainer = document.getElementById(`report_${this.idValue}_generating`)
    generatingContainer.innerHTML = html
  }
}
