import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "container", "hiddenField", "hiddenFieldTag" ]

  remove() {
    this.containerTarget.classList.add("hidden")
    this.hiddenFieldTagTarget.value = true
    this.hiddenFieldTarget.remove()
  }
}
