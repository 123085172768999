import { Controller } from "@hotwired/stimulus"
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';

// Connects to data-controller="calendar"
export default class extends Controller {
  static targets = [ "calendar" ]

  connect() {
    let calendar = new Calendar(this.calendarTarget, {
      plugins: [ dayGridPlugin, timeGridPlugin ],
      headerToolbar: {
        start: 'title',
        end: 'prev today next'
      },
      buttonText: {
        today: 'Today',
      },
      views: {
        timeGridWeek: {
          dayHeaderFormat: { weekday: 'short', day: '2-digit' }
        },
      },
      allDaySlot: false,
      dayMaxEvents: true,
      nowIndicator: true,
      showNonCurrentDates: false,
      initialView: this.calendarTarget.dataset.layout,
      events: this.calendarTarget.dataset.sourceUrl,
      eventClick: function(info) {
        info.jsEvent.preventDefault();

        if (info.event.url) {
          window.open(info.event.url);
        }
      }
    });

    calendar.render();
  }
}
