import { renderDatatable } from "../application"

columns = [
  { data: "name" },
  { data: "created_at" },
  { data: "action", searchable: false, orderable: false }
]

order = [[0, "asc"]]

renderDatatable($('#admin-tags-datatable'), columns, order)
