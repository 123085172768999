import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "source", "alert" ]

  copy(event) {
    event.preventDefault()
    navigator.clipboard.writeText(this.sourceTarget.href)

    if (this.hasAlertTarget) {
      this.showAlert()

      setTimeout(() => this.hideAlert(), 5000)
    }
  }

  showAlert() {
    this.alertTarget.classList.remove("hidden")
  }

  hideAlert() {
    this.alertTarget.classList.add("hidden")
  }
}
