import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "map", "fallback", "locationValue" ]

  connect() {
    fetch(`https://www.google.com/maps/embed/v1/place?key=AIzaSyCoFNZNq0NI4EwQIKJCYzsMqK0T0ulgjFA&q=${this.locationValueTarget.textContent}`)
    .then(response => {
      if (response.ok) {
        this.mapTarget.src = response.url
      } else {
        this.hideMap()
      }
      return response
    })
    .catch((error) => {
      this.hideMap()
    })
  }

  hideMap() {
    this.fallbackTarget.classList.remove("hidden")
    this.mapTarget.classList.add("hidden")
  }
}
