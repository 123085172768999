import { Application } from '@hotwired/stimulus'
import PlacesAutocomplete from 'stimulus-places-autocomplete'
import Reveal from 'stimulus-reveal-controller'
import { Dropdown, Modal } from 'tailwindcss-stimulus-components'

const application = Application.start()

// Configure Stimulus development experience
application.debug = false
window.Stimulus   = application

// Third-party controllers
application.register('dropdown', Dropdown)
application.register('modal', Modal)
application.register('places', PlacesAutocomplete)
application.register('reveal', Reveal)

window.initAutocomplete = function () {
  const event = new Event('google-maps-callback', {
    bubbles: true,
    cancelable: true,
  })
  window.dispatchEvent(event)
}

export { application }
