import { Controller } from "@hotwired/stimulus"
import Cropper from "cropperjs"

// Connects to data-controller="images"
export default class extends Controller {
  static targets = [ "source", "output", "signedId" ]
  static values = {
    uploadPath: String
  }

  connect() {
    // Check if the image is already loaded, useful for cached images
    if (this.sourceTarget.complete && this.sourceTarget.naturalHeight !== 0) {
      this.initialiseCropper();
    } else {
      this.sourceTarget.addEventListener('load', () => this.initialiseCropper());

      this.sourceTarget.addEventListener('error', (e) => {
        console.error("Image failed to load:", e);
      });
    }
  }

  initialiseCropper() {
    if (!this.cropper) {
      this.cropper = new Cropper(this.sourceTarget, {
        minContainerWidth: 600,
        minContainerHeight: 500,
        minCropBoxWidth: 40,
        minCropBoxHeight: 40
      });
    }
  }

  crop() {
    const filename = this.sourceTarget.dataset.filename;
    const fileType = this.sourceTarget.dataset.type;
    const imageId = this.sourceTarget.dataset.id;

    this.cropper.getCroppedCanvas().toBlob((blob) => {
      let formData = new FormData();
      formData.append('cropped_image', blob, filename);
      formData.append('image_id', imageId);

      fetch(this.uploadPathValue, {
        method: 'POST',
        body: formData,
        headers: {
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        },
        credentials: 'include',
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        this.outputTarget.src = data.url

        if (this.hasSignedIdTarget) {
          this.signedIdTarget.value = data.signed_id
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      })
    }, fileType)
  }
}
