import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form", "codeSection", "codeDisplay", "copyButton", "copyButtonText", "fullWidthLink", "previewFrame", "loadingIndicator"]
  static values = { url: String }

  connect() {
    this.updatePreview();
  }

  formSubmit(event) {
    this.updatePreview()
  }

  updatePreview() {
    this.showLoading()
    const url = this.generateUrl()
    this.previewFrameTarget.src = url
    this.fullWidthLinkTarget.href = url
    this.previewFrameTarget.addEventListener('load', this.hideLoading.bind(this), { once: true })
  }

  showLoading() {
    this.loadingIndicatorTarget.classList.remove('hidden')
    this.previewFrameTarget.classList.add('opacity-30')
  }

  hideLoading() {
    this.loadingIndicatorTarget.classList.add('hidden')
    this.previewFrameTarget.classList.remove('opacity-30')
  }

  generateUrl() {
    const formData = new FormData(this.formTarget)
    const params = new URLSearchParams()

    // Only include necessary parameters
    const defaultParams = ['view', 'type', 'search', 'event_colour']
    const listParams = ['images', 'text_colour', 'button_colour']
    const calendarParams = ['calendar_type']

    if (formData.get('view') === 'calendar') {
      defaultParams.push(...calendarParams)
    } else {
      defaultParams.push(...listParams)
    }

    defaultParams.forEach(param => {
      const value = formData.get(param)

      if (value) {
        if (value === 'true' || value === 'false') {
          params.append(param, value === 'true')
        } else {
          params.append(param, value)
        }
      }
    })

    return `${this.urlValue}?${params.toString()}`;
  }

  generateCode(event) {
    event.preventDefault()

    const embedCode = `<iframe src="${this.generateUrl()}" width="100%" height="600" frameborder="0"></iframe>`

    this.codeDisplayTarget.textContent = embedCode
    this.codeSectionTarget.classList.remove("hidden")
    this.updatePreview()
  }

  copyCode() {
    navigator.clipboard.writeText(this.codeDisplayTarget.textContent).then(() => {
      this.copyButtonTarget.classList.remove("btn-gray")
      this.copyButtonTarget.classList.add("btn-success")

      const originalText = this.copyButtonTextTarget.textContent
      this.copyButtonTextTarget.textContent = this.copyButtonTextTarget.dataset.copiedLabel

      setTimeout(() => {
        this.copyButtonTarget.classList.remove("btn-success")
        this.copyButtonTarget.classList.add("btn-gray")
        this.copyButtonTextTarget.textContent = originalText
      }, 3000)
    })
  }
}
