import { format, parseISO, addDays, addHours, addMinutes, addMonths, endOfMonth, isSameDay } from 'date-fns';

document.addEventListener('alpine:init', () => {
  Alpine.data('createEvent', () => ({
    showScheduleModal: false,
    showRecurringModal: false,
    startDate: '',
    startTime: '',
    endDate: '',
    endTime: '',
    schedules: [{ date: '', isoDate: '', prettyDate: '', time: '' }],
    newSchedules: [{ date: '', isoDate: '', prettyDate: '', time: '' }],
    duration: { hours: 0, minutes: 0 },
    frequency: 'Daily',
    interval: 1,
    lastSession: '',

    addNewSchedule() {
      const lastSchedule = this.newSchedules[this.newSchedules.length - 1];
      const lastScheduleDate = new Date(lastSchedule.date)

      let newDate;
      if (this.monthlyInterval()) {
        newDate = addMonths(lastScheduleDate, this.frequencyInterval());

        const firstScheduleDate = new Date(this.schedules[0].date);
        if (isSameDay(firstScheduleDate, endOfMonth(firstScheduleDate))) {
          newDate = endOfMonth(newDate);
        }
      } else {
        newDate = addDays(lastScheduleDate, this.frequencyInterval());
      }

      this.newSchedules.push(
        {
          date: newDate,
          isoDate: format(newDate, 'yyyy-MM-dd'),
          prettyDate: newDate.toDateString(),
          time: lastSchedule.time
        }
      );
    },

    calculateLastSchedule() {
      const start = new Date(this.startDate);
      const multiplier = this.frequencyInterval() * (this.interval - 1);

      let newDate;
      if (this.monthlyInterval()) {
        newDate = addMonths(start, multiplier);
      } else {
        newDate = addDays(start, multiplier);
      }

      this.lastSession = format(newDate, 'PPP');
    },

    calculateScheduleDuration() {
      const start = parseISO(this.startDate + 'T' + this.startTime);
      const end = parseISO(this.endDate + 'T' + this.endTime);
      const diff = end - start;

      if (diff < 0) {
          return 'Invalid date range';
      }

      this.duration = { hours: Math.floor(diff / 3600000), minutes: Math.floor((diff % 3600000) / 60000) };
    },

    createRecurringSchedules() {
      this.resetSchedules();

      for (let i = 0; i < this.interval - 1; i++) {
        this.addNewSchedule();
      }
    },

    createSchedule(isoDate, timeValue) {
      let date = new Date(isoDate);

      return { date: date, isoDate: isoDate, prettyDate: date.toDateString(), time: timeValue }
    },

    frequencyInterval() {
      const frequencyMapping = {
        'Daily': 1,
        'Weekly': 7,
        'Fortnightly': 14,
        'Monthly': 1
      };

      return frequencyMapping[this.frequency] || undefined;
    },

    monthlyInterval() {
      return this.frequency == 'Monthly'
    },

    removeSchedule(scheduleToRemove) {
      const index = this.schedules.findIndex(schedule => schedule.date === scheduleToRemove.date);

      if (index > -1) {
        this.schedules.splice(index, 1);
        this.resetNewSchedules();
        this.updateStartDateTime();
      }
    },

    resetNewSchedules() {
      this.newSchedules = [...this.schedules];
    },

    resetSchedules() {
      this.schedules = [{ date: '', isoDate: '', prettyDate: '', time: '' }];

      this.schedules[0] = this.createSchedule(this.startDate, this.startTime);
      this.resetNewSchedules();
    },

    saveSchedules() {
      this.newSchedules = this.newSchedules.slice().sort((a, b) => {
        return new Date(a.isoDate) - new Date(b.isoDate);
      });

      this.schedules = [...this.newSchedules];
      this.updateStartDateTime();
    },

    setEndDateTime() {
      let start = parseISO(this.startDate + 'T' + this.startTime);

      start = addHours(start, Number(this.duration.hours));
      start = addMinutes(start, Number(this.duration.minutes));

      this.endDate = format(start, 'yyyy-MM-dd');
      this.endTime = format(start, 'HH:mm');
    },

    updateNewSchedule(index, newDate, newTime) {
      this.newSchedules[index] = this.createSchedule(newDate, newTime);
    },

    updateStartDateTime() {
      const firstSchedule = this.schedules[0];
      this.startDate = firstSchedule.isoDate;
      this.startTime = firstSchedule.time;

      this.setEndDateTime();
    },

    validateInterval() {
      if (this.interval > 24) {
        this.interval = 24;
      }
    },

    init() {
      this.startDate = this.$el.getAttribute('data-start-date');
      this.startTime = this.$el.getAttribute('data-start-time');
      this.endDate = this.$el.getAttribute('data-end-date');
      this.endTime = this.$el.getAttribute('data-end-time');
      this.lastSession = this.startDate;

      this.resetSchedules();
      this.calculateScheduleDuration();
      this.calculateLastSchedule();

      this.$watch('startDate', value => { this.setEndDateTime(); this.updateNewSchedule(0, value, this.startTime); this.calculateScheduleDuration(); this.calculateLastSchedule(); });
      this.$watch('startTime', value => { this.setEndDateTime(); this.updateNewSchedule(0, this.startDate, value); this.calculateScheduleDuration(); });
      this.$watch('endDate', value => this.calculateScheduleDuration());
      this.$watch('endTime', value => this.calculateScheduleDuration());
      this.$watch('frequency', value => this.calculateLastSchedule());
      this.$watch('interval', value => { this.validateInterval(); this.calculateLastSchedule(); });
    }
  }));
});
