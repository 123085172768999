import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["fileList"]

  updateFileList(event) {
    const files = event.target.files
    this.fileListTarget.innerHTML = ""

    for (let i = 0; i < files.length; i++) {
      const fileElement = document.createElement("div")
      fileElement.textContent = files[i].name
      fileElement.classList.add("truncate")
      this.fileListTarget.appendChild(fileElement)
    }
  }
}
