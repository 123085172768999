import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["popup", "reactionIcon"]

  connect() {
    this.addClickOutsideListener()
  }

  disconnect() {
    this.removeClickOutsideListener()
  }

  toggle() {
    if (this.popupTarget.classList.contains("hidden")) {
      this.show()
    } else {
      this.hide()
    }
  }

  show() {
    this.popupTarget.classList.remove("hidden")
    this.animateIcons()
  }

  hide() {
    this.popupTarget.classList.add("hidden")
  }

  animateIcons() {
    this.reactionIconTargets.forEach((icon, index) => {
      icon.classList.add("opacity-0")

      setTimeout(() => {
        icon.classList.remove("opacity-0")
        icon.classList.add("transition", "duration-300", "ease-in", "opacity-100")
      }, 50 * index)
    })
  }

  addClickOutsideListener() {
    this.clickOutsideHandler = (event) => {
      if (!this.element.contains(event.target) && !this.popupTarget.classList.contains("hidden")) {
        this.hide()
      }
    }
    document.addEventListener("click", this.clickOutsideHandler)
    document.addEventListener("touchstart", this.clickOutsideHandler)
  }

  removeClickOutsideListener() {
    document.removeEventListener("click", this.clickOutsideHandler)
    document.removeEventListener("touchstart", this.clickOutsideHandler)
  }
}
