import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["submit", "buttonText", "spinner"]

  connect() {
    this.validate({ target: this.element.querySelector('trix-editor') })
  }

  validate(event) {
    const trixEditor = event.target
    const noContent = trixEditor.value.trim() === ""

    this.submitTarget.disabled = noContent
    this.submitTarget.classList.toggle("cursor-not-allowed", noContent)
  }

  submitForm(event) {
    this.submitTarget.disabled = true
    this.submitTarget.classList.add("cursor-not-allowed")
    this.buttonTextTarget.classList.add("hidden")
    this.spinnerTarget.classList.remove("hidden")
  }
}
