import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "form", "submitButton" ]
  static values = {
    submitDuration: { type: Number, default: 750 },
    submitButtonHtml: String
  }

  connect() {
    this.timeout = null

    if (this.hasSubmitButtonTarget) {
      this.submitButtonHtmlValue = this.submitButtonTarget.outerHTML
    }
  }

  save() {
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.showProgressButton();
      this.formTarget.requestSubmit();
    }, this.submitDurationValue);
  }

  instantSave() {
    this.formTarget.requestSubmit();
  }

  success() {
    this.submitButtonTarget.outerHTML = this.submitButtonHtmlValue
  }

  error() {
    this.submitButtonTarget.outerHTML = this.submitButtonHtmlValue
  }

  showProgressButton() {
    if (this.hasSubmitButtonTarget) {
      this.submitButtonTarget.outerHTML = `
        <button type="button" class="btn btn-primary flex justify-center items-center cursor-not-allowed w-full sm:w-auto" disabled="" data-autosave-target="submitButton">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="animate-spin -ml-1 mr-3 size-5 text-white">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
          Saving...
        </button>
      `
    }
  }
}
