import { Controller } from "@hotwired/stimulus"
import Tribute from "tributejs"
import Trix from "trix"

export default class extends Controller {
  static targets = [ "field" ]
  static values = {
    sourceUrl: String
  }

  connect() {
    this.editor = this.fieldTarget.editor
    this.initializeTribute()
  }

  disconnect() {
    this.tribute.detach(this.fieldTarget)
  }

  initializeTribute() {
    this.tribute = new Tribute({
      allowSpaces: true,
      lookup: 'name',
      values: (text, callback) => this.fetchMembers(text, callback),
      menuItemTemplate: (item) => {
        if (item.original.name === 'everyone') {
          return `
            <div class="flex items-center my-1 text-sm">
              <span class="size-8 flex items-center justify-center bg-gray-200 rounded-full">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 stroke-black">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z" />
                </svg>
              </span>
              <span class="ml-2 font-bold">Everyone</span>
            </div>
          `;
        }
        return `
          <div class="flex items-center my-1 text-sm">
            ${item.original.avatar_html}
            <span class="ml-2">${item.original.name}</span>
          </div>
        `;
      },
    })

    this.tribute.attach(this.fieldTarget)
    this.tribute.range.pasteHtml = this._pasteHtml.bind(this)
    this.fieldTarget.addEventListener("tribute-replaced", this.replaced.bind(this))
  }

  fetchMembers(text, callback) {
    const everyoneOption = {
      sgid: 'everyone',
      name: 'everyone',
    };

    fetch(`${this.sourceUrlValue}?query=${encodeURIComponent(text)}`)
      .then(response => response.json())
      .then(members => {
        if (text.length === 0 || 'everyone'.startsWith(text.toLowerCase())) {
          members.unshift(everyoneOption);
        }
        callback(members);
      })
      .catch(error => {
        callback([everyoneOption]);
      });
  }

  replaced(e) {
    let mention = e.detail.item.original

    let content;
    if (mention.name === 'everyone') {
      content = "<span class='mention everyone'>@everyone</span>";
    } else {
      content = `<span class="mention">@${mention.name}</span>`;
    }

    let attachment = new Trix.Attachment({
      sgid: mention.sgid,
      content: content,
    })

    this.editor.insertAttachment(attachment)
    this.editor.insertString(" ")
  }

  _pasteHtml(html, startPos, endPos) {
    const position = this.editor.getPosition()
    const text = this.editor.getDocument().toString()

    // Find the position of the '@' symbol
    let triggerPosition = text.lastIndexOf('@', position)

    // If '@' is not found or it's too far back, don't replace anything
    if (triggerPosition === -1 || position - triggerPosition > 50) {
      return
    }

    // Calculate the range to replace
    const replaceStartPosition = triggerPosition
    const replaceEndPosition = position

    // Set the selected range and delete the mention text
    this.editor.setSelectedRange([replaceStartPosition, replaceEndPosition])
    this.editor.deleteInDirection("backward")
  }
}
