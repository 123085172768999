import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  fees = {
    aud: {
      base_percentage: 1.75,
      transaction_fee: 0.30,
      symbol: '$'
    },
    gbp: {
      base_percentage: 1.5,
      transaction_fee: 0.20,
      symbol: '£'
    }
  }

  static targets = [ "price", "taxRate", "symbol", "currency", "ticketPrice", "stripeFee", "payout", "stripeFeeDetail" ]

  connect() {
    this.updateCart()
  }

  updateCart() {
    this.updatePriceSymbol();
    this.updateCartLineItems()
    this.updateStripeFeeDetail()
  }

  updateCartLineItems() {
    if (this.priceTarget.value < 1) {
      return
    }

    let ticketPrice = this.priceTarget.value * (1 + this.taxRateTarget.value/100)
    let stripeFee = this.stripeFee(ticketPrice)
    let payout = ticketPrice - stripeFee

    this.ticketPriceTarget.innerText = this.formatValue(ticketPrice)
    this.stripeFeeTarget.innerText = this.formatValue(stripeFee)
    this.payoutTarget.innerText = this.formatValue(payout)
  }

  updateStripeFeeDetail() {
    let country_fee = this.countryFee()

    this.stripeFeeDetailTarget.innerText = `(${country_fee.base_percentage}% + ${this.formatValue(country_fee.transaction_fee)})`
  }

  stripeFee(ticketPrice) {
    let country_fee = this.countryFee()

    return ticketPrice * (country_fee.base_percentage/100) + country_fee.transaction_fee
  }

  countryFee() {
    return this.fees[this.selectedCurrency().toLowerCase()]
  }

  selectedCurrency() {
    return this.currencyTarget.value
  }

  formatValue(value) {
    return this.selectedCurrencySymbol() + value.toFixed(2)
  }

  selectedCurrencySymbol() {
    let country_fee = this.countryFee()

    return country_fee.symbol
  }

  updatePriceSymbol() {
    this.symbolTarget.innerText = this.selectedCurrencySymbol()
  }
}
