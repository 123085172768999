// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "trix"
import "@rails/actiontext"
import "chartkick/chart.js"
import Alpine from 'alpinejs'

import "./controllers"
import "./create_event"
import "./datatables"

window.Alpine = Alpine;
Alpine.start();
