import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "form", "modal", "badge" ]

  formChanged = false
  navigateToUrl = ''

  connect() {
    this.formTarget.addEventListener("change", () => {
      this.formDirty();
    });

    // document.querySelectorAll('.trix-content').forEach((element) => {
    //   const initialValue = element.innerHTML;

    //   element.addEventListener('trix-blur', () => {
    //     const currentValue = element.innerHTML

    //     if (initialValue !== currentValue) {
    //       this.formDirty();
    //     }
    //   });
    // });

    this.visitHandler = (event) => this.leavingPage(event)

    window.addEventListener('beforeunload', this.visitHandler)
    document.addEventListener('turbo:before-visit', this.visitHandler)
  }

  disconnect() {
    window.removeEventListener('beforeunload', this.visitHandler)
    document.removeEventListener('turbo:before-visit', this.visitHandler)
  }

  formDirty() {
    this.formChanged = true;
    this.toggleBadge();
  }

  formClean() {
    this.formChanged = false;
    this.toggleBadge();
  }

  showModal() {
    this.modalTarget.classList.remove("hidden")
  }

  toggleBadge() {
    if (this.formChanged) {
      this.badgeTargets.forEach((badge) => { badge.classList.remove("hidden") })
    } else {
      this.badgeTargets.forEach((badge) => { badge.classList.add("hidden") })
    }
  }

  discard() {
    this.modalTarget.classList.add("hidden")
    this.formChanged = false
    this.toggleBadge()
    window.location.href = this.navigateToUrl
  }

  leavingPage(event) {
    if (this.formChanged) {
      event.preventDefault()

      this.navigateToUrl = event.detail.url

      if (event.type == 'beforeunload') {
        event.returnValue = true
      } else {
        this.showModal()
      }
    }
  }
}

