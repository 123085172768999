import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "menu", "selectInput", "selectedOption", "hiddenFormField", "option", "label", "revealItem", "revealItemInput" ]
  static values = {
    placeholder: String
  }

  menuTargetConnected() {
    this.originalMenuOptions = this.menuTarget.innerHTML;
    this.originalOptionTargets = this.optionTargets;
    this.revealOptionTarget = this.optionTargets.filter((optionTarget) => { return optionTarget.dataset.reveal == 'true' })
    this.showSelection()
  }

  autocomplete(event) {
    const query = event.srcElement.value;

    if (query.length < 2) {
      this.resetOptions()
      return
    }

    const remainingOptions = this.originalOptionTargets.filter((optionTarget) => {
      return optionTarget.textContent.toLowerCase().includes(query.trim().toLowerCase())
    })

    const results = remainingOptions.length > 0 ? remainingOptions : this.revealOptionTarget
    this.menuTarget.innerHTML = results.map(option => option.outerHTML).join('');
  }

  updateSelection(event) {
    const selectedLabel = event.srcElement
    const selectedId = event.srcElement.dataset.id

    this.hiddenFormFieldTarget.value = selectedId;
    this.selectedOptionTarget.value = selectedLabel.textContent.trim();

    this.optionTargets.map((target) => target.classList.remove('text-white', 'bg-blue-600'))
    selectedLabel.closest('li').classList.add('text-white', 'bg-blue-600')

    this.labelTargets.map((target) => target.classList.remove('font-semibold'))
    selectedLabel.classList.add('font-semibold')

    this.showSelection()
  }

  showSelection() {
    const selectedOption = this.fetchSelectedOption()

    if (!selectedOption) {
      this.hiddenFormFieldTarget.value = null
      this.selectedOptionTarget.placeholder = this.placeholderValue
      return
    }

    this.hiddenFormFieldTarget.value = selectedOption.dataset.id;
    this.selectedOptionTarget.value = selectedOption.textContent.trim();
    this.scrollToSelection(selectedOption)
    this.toggleRevealItem(selectedOption)
  }

  scrollToSelection(selectedOption) {
    selectedOption.scrollIntoView()
  }

  toggleRevealItem(selectedOption) {
    if (!this.hasRevealItemInputTarget) {
      return
    }

    if (selectedOption.dataset.reveal == "true") {
      this.revealItemTarget.classList.remove('hidden')
    } else {
      this.revealItemTarget.classList.add('hidden')
      this.revealItemInputTarget.value = null;
    }
  }

  fetchSelectedOption() {
    return this.optionTargets.find((target) => target.classList.contains('text-white', 'bg-blue-600'))
  }

  resetOptions() {
    this.menuTarget.innerHTML = this.originalMenuOptions;
  }
}

