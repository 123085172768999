import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs"

// Connects to data-controller="drag-and-drop"
export default class extends Controller {
  static targets = [ "list" ]

  connect() {
    this.sortable = Sortable.create(this.listTarget, {
      animation: 500,
      draggable: '.draggable-container',
      handle: '.handle',
      onEnd: this.reorder.bind(this)
    })
  }

  reorder() {
    const itemsArray = Array.from(this.draggableItems())

    itemsArray.forEach((item, index) => {
      this.updateHiddenPositionField(item, index + 1)
    })
  }

  sortAlphabetically(event) {
    event.preventDefault();

    const itemsArray = Array.from(this.draggableItems())

    itemsArray.sort((a, b) => {
      let inputA = a.querySelector('input[type="text"]').value.trim().toLowerCase()
      let inputB = b.querySelector('input[type="text"]').value.trim().toLowerCase()

      return inputA.localeCompare(inputB)
    })

    itemsArray.forEach((item, index) => {
      this.listTarget.appendChild(item)

      this.updateHiddenPositionField(item, index + 1)
    })
  }

  draggableItems() {
    return this.element.querySelectorAll('.draggable-container')
  }

  updateHiddenPositionField(item, value) {
    const positionField = item.querySelector('input[type="hidden"][name*="[position]"]')

    if (positionField) {
      positionField.value = value
    }
  }
}
