import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "nestedSection", "toggleSection", "actionsSection" ]

  add(event) {
    event.preventDefault();

    let time = new Date().getTime();
    let addLink = event.target;
    let regexp = new RegExp(addLink.dataset.id, 'g');

    this.nestedSectionTarget.insertAdjacentHTML('beforeend', addLink.dataset.fields.replace(regexp, time));

    if (this.hasToggleSectionTarget && this.toggleSectionTarget.dataset.trigger == addLink.text) {
      this.toggleSectionTarget.classList.add('hidden');
    }

    this.showActionsSection(addLink)
  }

  remove(event) {
    event.preventDefault();

    let section = event.target.closest('.nested-association-parent');

    if (section.querySelector('input[type="hidden"]')) {
      section.querySelector('input[type="hidden"]').value = 1
    }

    section.classList.add('hidden');

    this.hideActionsSection()
  }

  showToggleSection() {
    if (this.hasToggleSectionTarget && this.toggleSectionTarget.classList.value.includes('hidden')) {
      this.toggleSectionTarget.classList.remove('hidden');
    }
  }

  showActionsSection(addLink) {
    if (this.hasActionsSectionTarget && this.actionsSectionTarget.dataset.trigger == addLink.text) {
      this.actionsSectionTarget.classList.remove('hidden');
    }
  }

  hideActionsSection() {
    if (this.hasActionsSectionTarget) {
      const hiddenItems = this.nestedSectionTarget.querySelectorAll('.nested-association-parent.hidden')
      const visibleItems = this.nestedSectionTarget.querySelectorAll('.nested-association-parent')

      if (hiddenItems.length == visibleItems.length) {
        this.actionsSectionTarget.classList.add('hidden');
      }
    }
  }
}
