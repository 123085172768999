import { renderDatatable } from "../application"

columns = [
  { data: "title" },
  { data: "applicants_count" },
  { data: "user" },
  { data: "created_at" }
]

order = [[3, "desc"]]

renderDatatable($('#admin-screening-jobs-datatable'), columns, order)
